@import url("https://fonts.googleapis.com/css?family=Open+Sans");

@font-face {
  font-family: Font Awesome 5 Free;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("./fontawsome/webfonts/fa-regular-400.eot");
  src: url("./fontawsome/webfonts/fa-regular-400.eot?#iefix")
      format("embedded-opentype"),
    url("./fontawsome/webfonts/fa-regular-400.woff2") format("woff2"),
    url("./fontawsome/webfonts/fa-regular-400.woff") format("woff"),
    url("./fontawsome/webfonts/fa-regular-400.ttf") format("truetype"),
    url("./fontawsome/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("./fontawsome/webfonts/fa-brands-400.eot");
  src: url("./fontawsome/webfonts/fa-brands-400.eot?#iefix")
      format("embedded-opentype"),
    url("./fontawsome/webfonts/fa-brands-400.woff2") format("woff2"),
    url("./fontawsome/webfonts/fa-brands-400.woff") format("woff"),
    url("./fontawsome/webfonts/fa-brands-400.ttf") format("truetype"),
    url("./fontawsome/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: Font Awesome 5 Bold;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("./fontawsome/webfonts/fa-solid-900.eot");
  src: url("./fontawsome/webfonts/fa-solid-900.eot?#iefix")
      format("embedded-opentype"),
    url("./fontawsome/webfonts/fa-solid-900.woff2") format("woff2"),
    url("./fontawsome/webfonts/fa-solid-900.woff") format("woff"),
    url("./fontawsome/webfonts/fa-solid-900.ttf") format("truetype"),
    url("./fontawsome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}

@font-face {
  font-family: "Font Awesome 5 Font";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("./fontawsome/Font Awesome 5 Free-Regular-400.eot");
  src: url("./fontawsome/Font Awesome 5 Free-Regular-400.eot?#iefix")
    format("embedded-opentype");
}

.App {
  text-align: center;
  font-family: Font Awesome 5 Free;
  letter-spacing: 1px;
  font-size: 13px;
}

.App-icon {
  font-family: "Font Awesome 5 Brands";
  letter-spacing: 1px;
  font-size: 16px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  background-size: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-head {
  background: linear-gradient(180deg, #ffd66e 50%, #ffffff 50%);
  padding-top: 30px;
  min-height: 300px;
}

.discount-header {
  padding-top: 10px;
}

.discount-body-web {
  display: block;
}

.discount-body-mobile {
  display: none;
}

#mobile-navbar {
  display: none;
}

#desktop-navbar {
  display: flex;
  padding-left: 100px;
}

@media only screen and (max-width: 500px) {
  /* For mobile phones: */

  .App-head {
    min-height: 100px;
  }

  .discount-header {
    padding-top: 30px;
  }
  .discount-body-web {
    display: none;
  }

  .discount-body-mobile {
    display: block;
  }

  .alert {
    width: 80%;
  }

  #mobile-navbar {
    display: block;
  }

  #desktop-navbar {
    display: none;
  }

  .App-label {
    text-align: center;
    padding: 0;
  }
}

.App-label {
  text-align: left;
  padding: 0.1rem 0.2rem;
  padding-right: 0px;
}

.App-label-title {
  font-weight: bolder;
  font-size: 16px;
  text-transform: uppercase;
}

.App-input {
  background-color: #282c34;
  border-radius: 0rem;
}

.Login-Box {
  padding-top: 100px;
}

.App-link {
  color: #61dafb;
}

.App-navbar-text {
  padding-top: 5px;
  font-size: 16px;
}

.App-navbar-text-top {
  padding-top: 35px;
  font-size: 16px;
}

form {
  padding: 5px;
}

input,
textarea {
  background-color: #e5e5e5 !important;
  background-image: none !important;
  border: 0px !important;
}

input:disabled {
  background-color: white !important;
}

input:placeholder-shown {
  background-color: #e5e5e5 !important;
}

.App-img-sm {
  vertical-align: top;
  padding-top: 1px;
  height: 18px;
}

input:-webkit-autofill {
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 0 0px 9999px #e5e5e5;
}

.navbar {
  padding: 0px;
  align-items: start;
}

.container {
  padding: 0px;
}

.input-group-text {
  padding: 0px 5px 0px 0px;
  text-align: left;
  font-size: inherit;
  background-color: inherit;
  border: 0px;
  min-width: 125px;
}

.input-group-short {
  min-width: 50px !important;
}

.input-group {
  padding-bottom: 10px;
}

.input-search {
  background-color: yellow !important;
}

.btn {
  background-color: white;
  padding: 0.1rem 0.3rem;
  border-color: black;
  border-width: 3px;
  border-radius: 0.5rem;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-stretch: ultra-expanded;
  color: black;
  font-size: 13px;
  min-width: 90px;
}

.btn:hover {
  border-color: black;
  background-color: black;
  color: white;
}

.App-btn-group {
  max-width: 75px !important ;
  min-width: 10px;
  background-color: transparent;
  border: 0px;
  color: #212529;
  letter-spacing: 1px;
  font-weight: normal;
  user-select: none;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.App-btn-group.active {
  background-color: transparent !important;
  border-width: 0px !important;
  color: black !important;
  font-weight: bolder !important;
  z-index: 1;
  text-align: center;
}

.App-btn-group:hover {
  background-color: transparent !important;
  border: 0px !important;
  color: #3959bd !important;
  font-weight: bolder !important;
  z-index: 1;
  text-align: center;
}

.App-col-r-search {
  padding-left: 30px;
}

.App-col-l-search {
  padding-right: 30px;
}

:target {
  padding: 0.1rem 0.3rem !important;
  background-color: transparent !important;
  border: 0px !important;
  font-weight: bolder !important;
  z-index: 1;
}

.nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.nav-link:hover {
  color: rgba(0, 0, 0, 0.5);
}

.nav-item.active {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0px 0px;
}

.form-control {
  border-radius: 0;
  padding: 0.1rem 0.2rem;
  height: calc(1.5em + 0.1rem + 2px);
  font-size: inherit;
  background-color: #e5e5e5;
}

.form-group {
  margin-bottom: 0.2rem;
}
.App-Option-separator {
  padding-top: 5px !important;
  max-width: 2px;
}

.App-Option-Select {
  padding-top: 4px !important;
  text-align: center;
}

.App-page-label {
  min-width: 125px;
  max-width: 125px;
  width: 125px;
}

.App-page-label-wider {
  min-width: 350px;
  max-width: 350px;
  width: 350px;
}

.App-modal-label {
  min-width: 200px;
  max-width: 200px;
}

.App-searchLine {
  vertical-align: bottom;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-icon-button {
  border: 0px !important;
  padding: 0px !important;
  margin: 0.5px 0px 3px !important;
  min-width: 0px !important;
  height: 18px !important;
  background-color: transparent;
  color: black;
}
.App-icon-button:active {
  background-color: transparent !important;
  border: 0px !important;
  color: black !important;
  font-weight: bolder !important;
  z-index: 1;
}
.App-icon-button:hover {
  background-color: transparent !important;
  border: 0px !important;
  color: black !important;
  font-weight: bolder !important;
  z-index: 1;
}

.App-Alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
}

.alert {
  position: fixed;
  top: 15px;
  left: 35%;
  width: 30%;
  z-index: 999999;
}

.reportspage {
  position: relative;
  /*top: 15px;
  left: 35%;*/
  width: 30%;
  /*z-index: 999999;*/
}

.default-alert {
  position: static;
  width: 100%;
}

.App-icon-width {
  width: 18px;
  max-width: 18px;
  align-self: end;
}

.App-fill-width {
  width: available;
}

.textarea {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
}

.App-select {
  width: 325px;
}

.App-select-invoice {
  width: 272px;
}

.App-select-file {
  width: 287px;
}

.App-select-file-format {
  width: 163px;
}

.App-select-reports {
  width: 140px;
}

.App-btn-small {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}
.__react_component_tooltip {
  padding: 3px 5px !important;
  border-radius: 0.5rem;
}

.input-currency {
  text-align: right;
}

.input-currency:disabled {
  background-color: white !important;
}

.number-minw {
  min-width: 100px !important;
}

.Excel-export {
  font-size: 18px;
  color: #1e90ff;
}

.Yellow-bold-cell {
  background-color: yellow;
  font-weight: 700;
}

/*Moveable CSS*/
.container-moveable {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-42%, 50%);
}

.moveable-img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.firebrick {
  filter: invert(10%) sepia(97%) saturate(4945%) hue-rotate(358deg)
    brightness(98%) contrast(113%);
}

.forestgreen {
  filter: invert(40%) sepia(74%) saturate(558%) hue-rotate(71deg)
    brightness(88%) contrast(90%);
}

.moveable-div {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  font-size: 18;
  display: inline-block;
}

.moveable {
  position: relative;
}

.origin {
  position: absolute;
  left: 0;
}

.moveable-line {
  opacity: 0;
}

.faBlue {
  color: dodgerblue;
  font-size: 1.4em;
}

.faRed {
  color: firebrick;
  font-size: 1.4em;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
}

.table thead th {
  border-left: 3px solid black !important;
}

.btn:disabled {
  background-color: black;
  padding: 0.1rem 0.3rem;
  border-color: black;
  border-width: 3px;
  border-radius: 0.5rem;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-stretch: ultra-expanded;
  color: white;
  font-size: 13px;
  min-width: 90px;
  opacity: 0.65;
}
